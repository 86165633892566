<template>
    <div>
        <div class="card mb-3 profile-card" style="width: 100%" v-loading="loading" element-loading-text="Loading..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
            >
            <div class="card-header bg-light">
                <h3 class="title fw-normal mb-0 p-2">Subscription Information</h3>
            </div>
            <template>
                <el-form ref="activePlan">
                    <el-row class="mp-5">
                        <el-col :span="24">
                            <div class="card-body subscription">

                                <table class="table">
                                    <tr class="tr">
                                        <th class="th">Active Plan :</th>
                                        <td class="td"><Strong>{{ planExpireDetails.activePlan }}</Strong></td>
                                    </tr>
                                    <tr class="tr">
                                        <th class="th">Expire at :</th>
                                        <td class="td">
                                            <span v-if="planExpireDetails && planExpireDetails.diff&&planExpireDetails.diff > 0 && planExpireDetails.diff <= 5">
                                                <el-tag size="small" type="danger" effect="dark">{{ this.$moment(planExpireDetails.expireDate).format("MM-DD-YYYY hh:mm a")}}</el-tag>&nbsp;&nbsp; <span v-if="diff > 0">Expires in {{ planExpireDetails.diff }} days</span>
                                            </span>
                                            <span v-else-if="planExpireDetails && planExpireDetails.diff&&planExpireDetails.diff>5">
                                                <el-tag size="small" type="success" effect="dark">
                                                    {{this.$moment(planExpireDetails.expireDate).format("MM-DD-YYYY hh:mm a")}}
                                                </el-tag>

                                            </span>
                                            <span v-else>
                                                <el-tag size="small" type="danger" effect="dark">
                                                    Expired
                                                </el-tag>

                                            </span>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="mp-5" v-if="planExpireDetails.activePlan==='Free Plan' || planExpireDetails.diff<=5">
                        <el-col :span="24">
                            <div class="card-body upgrade-block ">
                                <el-row>
                                    <el-col :span="24">
                                        <h3>Upgrade your plan</h3>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <p>Upgrade to our advanced plan for access to enhanced features and
                                            capabilities. Take your experience to the next level with our superior
                                            offering.</p>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24" class="align-center"><el-button type="danger"
                                            @click="seePlans">Upgrade <i
                                                class="el-icon-upload el-icon-right"></i></el-button></el-col>
                                </el-row>

                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </template>
        </div>
        <br />
        <div class="card mb-3 profile-card-second" style="width: 100%" element-loading-text="Payment History Loading..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
            >
            <div class="card-header bg-light">
                <h3 class="title fw-normal mb-0 p-2">Payment Information</h3>
            </div>
            <el-row class=" mp-5">
                <el-col :span="24">
                    <el-collapse>
                        <el-collapse-item title="Invoices" name="1">
                            <template>
                                <el-table :data="invoiceData" style="width: 100%">
                                    <el-table-column prop="inv_date" label="Date" width="180">
                                    </el-table-column>
                                    <el-table-column prop="invoice_id" label="Invoice Id" width="180">
                                    </el-table-column>
                                    <el-table-column prop="subscription_name" label="Subscription Id" width="180">
                                    </el-table-column>
                                    <el-table-column prop="plan_name" label="Plan">
                                    </el-table-column>
                                    <el-table-column prop="amount" label="Invoice Amount">
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-collapse-item>
                    </el-collapse>

                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "SubscriptionInformation",
    data() {
        return {
            expireAt: '',
            loading: false,
            updatePersonalInformation: {
                first_name: "",
                last_name: "",
            },
            planSelected: "",
            featuresList: [],
            planList: [],
            plan: {},
            additionalFeatures: [],
            planPrice: 0.0,
            additionalPrice: 0.0,
            totalPrice: 0.0,
            paymentType: "",
            stripeKey:
                "",
            paymentParams: {
                amount: 0,
                currency: "usd",
                name: "",
                email: "",
                engravingText: "",
                address: {
                    street: "123 Something Lane",
                    city: "San Francisco",
                    state: "CA",
                    zip: "94607",
                },
                card: {
                    number: "4242424242424242",
                    cvc: "123",
                    exp_month: "01",
                    exp_year: "24",
                },
                token_from_stripe: "",
                no_of_users: 1,
                period: "",
                selected_plan_id: "",
                plan: {},
                promo_code: "",
            },
            stripeProductsList: {},
            stripePlan: {},
            userInfo: {},
            transactionHistory: [],
            isExipred: false,
            isNearExpiry: false,
            isSubscription: true,
            promoCodeDetails: {},
            discountPrice: 0.0,
            userCount: 0,
            diff: 0,
            planExpireDetails: {
                activePlan: '',
                expireDate: "",

            },
            invoiceData: []
        };
    },
    computed: {
        ...mapGetters("auth", [
            "getAuthenticatedUser",
            "getCompanyUsers",
            "getActiveWorkspace",
        ]),
        ...mapGetters("paymentGateway", [ "getPaymentsHistory"]),
    },
    created() {
        this.userInfo = this.getAuthenticatedUser;
        this.$set(this.paymentParams, "email", this.userInfo.email);
        this.$set(
            this.paymentParams,
            "name",
            this.userInfo.first_name + " " + this.userInfo.last_name
        );
    },
    mounted() {
        this.loading=true;
        this.getSubscriptionHistory();
        this.$store.commit("auth/setUpdateProfileErrors", null, { root: true });
    },
    methods: {
        async getPlanExpireDetails() {
            this.loading=true;
            if (this.getPaymentsHistory && this.getPaymentsHistory.data && this.getPaymentsHistory.data.length) {
                this.planExpireDetails =this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0]

                let invoicesData=this.getPaymentsHistory.data.map(async(inv)=>{
                    return {
                            inv_date:this.$moment(inv.invoiceInfo.template_data.invoice_date).format("DD-MM-YYYY"),
                            invoice_id:inv.invoiceInfo.template_data.invoice_id,
                            subscription_name: inv.invoiceInfo.template_data['subscription/name'],
                            customer_name:inv.invoiceInfo.template_data['customer/name'],
                            plan_name:inv.invoiceInfo.template_data['plan/name'],
                            amount: Math.round((parseFloat(inv.invoiceInfo.template_data.net_amount) + Number.EPSILON) * 100) / 100 +" "+inv.invoiceInfo.template_data.net_amount_currency

                    }
                })
                this.invoiceData= await Promise.all(invoicesData)
                this.loading=false
            }
            else if (this.getPaymentsHistory && this.getPaymentsHistory.planFeatures && this.getPaymentsHistory.planFeatures.length) {
                this.planExpireDetails =this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0];
                this.loading=false
            }
            else {
                this.expireAt = new Date(this.getActiveWorkspace.created_at);
                this.expireAt.setDate(this.expireAt.getDate() + 6);
                let endDate = this.$moment(this.expireAt);
                let startDate = this.$moment(new Date());
                this.planExpireDetails = {
                    activePlan: 'Free Plan',
                    expireDate: new Date(),
                    diff:endDate.diff(startDate, 'days')
                }
                this.loading=false
            }

        },
        async getSubscriptionHistory() {
            try {
                this.loading = true;
                let email = '';
                await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", { email });
                await this.getPlanExpireDetails();
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                console.log(error)
            }
        },
        async seePlans() {
            this.loading = true;
            setTimeout(() => {
                this.$router.push({ path: "/plans-list" });
            }, 1500)
        },
    },
};
</script>
<style  lang="scss" scoped>
.mp-5 {
    padding: 1rem;
    margin: 1rem;
}

.subscription {
    width: 100%;

    .table {
        width: 100%;
        border-collapse: collapse;

        .th {
            border: 1px solid #80808038;
            text-align: center;
            padding: 0.5rem;
        }

        .td {
            border: 1px solid #80808038;
            padding: 0.5rem;
        }

        .tr {
            padding: 0.5rem;
        }
    }
}

.upgrade-block {
    border: 1px solid #80808038;
    padding: 1rem;
    //margin: 0.5rem;
    border-radius: 5px;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.profile-card{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
.profile-card-second{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
</style>